<template>
  <b-card id="login-card">
    <div class="text-center">
      <img
        src="../img/new-logo.svg"
        style="margin: 3rem 0"
        height="88px"
        alt=""
      />
    </div>
    <b-alert show variant="success" :class="{ hidden: message === '' }">
      {{ message }}
    </b-alert>
    <div style="margin-bottom: 3rem; display: flex; justify-content: center">
      <b-form style="max-width: 450px; width: 100%">
        <h1 style="font-weight: bolder; font-size: 30px; color: #4c4d4a">
          Recuperação de senha
        </h1>
        <b-input-group>
          <b-form-input
            id="recover"
            v-model="username"
            v-on:keyup.enter="recoverpwd"
            type="email"
            required
            :disabled="recoverDisabled"
            placeholder="E-mail"
          ></b-form-input>
          <b-input-group-append
            style="
              display: flex;
              align-items: center;
              position: absolute;
              right: 10px;
              top: 27px;
              z-index: 5;
            "
          >
            <b-icon
              font-scale="1.4"
              color="#707070
"
              icon="person"
            ></b-icon>
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="mb-2">
          <b-button
            id="login-button"
            block
            @click="recoverpwd"
            :disabled="recoverDisabled"
            class="mb-2 mb-sm-2 btn-login"
            style="border-radius: 3px; background-color: #04a8f3; color: #fff;"
            >Recuperar Senha</b-button
          >
        </b-input-group>
        <b-input-group>
          <b-button
            block
            @click="login"
            class="mb-2 mb-sm-2 btn-register"
            style="
              border-radius: 3px;
              background-color: transparent;
              border: 2px solid #04a8f3;
              color: #04a8f3;
              font-weight: 600 !important;
            "
            >Acessar conta</b-button
          >
        </b-input-group>
        <b-alert show variant="danger" :class="{ hidden: !hasErrors() }">
          {{ error }}
        </b-alert>
      </b-form>
    </div>
  </b-card>
</template>

<script>
export default {
  namer: 'ForgotPassword',
  computed: {
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
  },
  data() {
    return {
      processing: false,
      recoverDisabled: false,
      username: '',
    }
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  methods: {
    login() {
      this.$store.dispatch('access/updateError', '')
      this.$router.push({ name: 'Signin' })
    },
    hasErrors() {
      return this.error
    },
    recoverpwd() {
      this.processing = true
      this.recoverDisabled = true
      if (!this.username) {
        this.$store.dispatch('access/updateError', 'Dados obrigatórios')
        return
      }
      this.$api
        .post('users/recuperarsenha', {
          username: this.username,
        })
        .then((res) => {
          this.username = ''
          if (res.data.message) {
            this.$store.dispatch('access/updateMessage', res.data.message)
            this.$router.push({ path: '/login' })
          } else if (res.data.error)
            this.$store.dispatch('access/updateError', res.data.error)
          this.recoverDisabled = false
        })
        .catch(() => {
          this.username = ''
          this.recoverDisabled = false
        })
      this.processing = false
    },
  },
}
</script>

<style></style>
